<template>
    <div class="row">
        <div class="col-12 wrapper-box-fiche" v-if="data.prix_inami"> 
            <div class="box-white-fiche" v-if="data.prix_inami['prix_inami_price'].length > 0" >
                <h3 class="text-orange">{{$t('Modalités de remboursement')}}</h3>         
                <template v-for="(item ,key) in data.prix_inami['prix_inami_price']" :key="key+'_price'">                   
                    <table class="comparateur2 border-top-gray" >
                        <tr>
                            <td class="bold"><strong> {{$t('Code CNK')}} </strong></td>
                            <td> {{ item['code_cnk'] ? item['code_cnk'] : '-'}}</td>
                        </tr>
                        <tr>
                            <td class="bold"><strong> {{$t('Délivrance')}} </strong></td>
                            <td> {{ item['model_autor'] ? item['model_autor'] : '-'}}</td>                        
                        </tr>
                        <tr>
                            <td class="bold"><strong> {{$t('Unité de tarification')}} </strong></td>
                            <td> {{ item['unite'] ? item['unite'] : '-'}}</td>                        
                        </tr>
                    </table>          
                    <template  v-if="item['prix_inami_sub_price'].length > 0">      
                        <table class="comparateur2 border-top-gray" :key="key+'_sub_price'">
                            <thead>
                                <tr>
                                    <th rowspan="2" class="gray" width="10%">Chap.-Paragr.</th>
                                    <th rowspan="2" class="gray" width="10%">Modèle autor.</th>
                                    <th rowspan="2" class="gray" width="15%">Groupe de remboursement</th>
                                    <th rowspan="2" class="gray" width="15%">Prix (€)</th>
                                    <th rowspan="2" class="gray" width="15%">Base de remboursement (€)</th>
                                    <th rowspan="2" class="gray" width="15%">Variation de prix (%)</th>
                                    <th colspan=2 class="gray" width="20%">Intervention patient </th>                               
                                </tr>
                                <tr>
                                    <th width="10%" class="gray" >VIPO</th>
                                    <th width="10%"  class="gray" >Actif</th>
                                </tr>
                            </thead>
                            <tr v-for="(value, k) in item['prix_inami_sub_price']" :key="'data_sub_price_'+k">
                                <td> {{ value['chap_paragr'] ? value['chap_paragr'] : '-'}}</td>
                                <td> {{ value['model_autor'] ? value['model_autor'] : '-' }} </td>
                                <td> {{ value['gorup_remboursement'] ? value['gorup_remboursement']: '-'}} </td>
                                <td> {{ value['prix'] ? format_french_number(value['prix']) : '-'}} </td>
                                <td> {{ value['base_remboursement'] ? format_french_number(value['base_remboursement']): '-'}} </td>
                                <td> {{ value['variation_prix'] ?  format_french_number(value['variation_prix']): '-'}} </td>
                                <td> {{ value['vipo'] ? format_french_number(value['vipo']): '-'}} </td>
                                <td> {{ value['actif'] ? format_french_number(value['actif']): '-'}} </td>
                            </tr>
                        </table>
                    </template>
                </template>
            </div> 
            <div class="box-white-fiche" v-if="data.prix_inami['niveau_prix'] || data.prix_inami['niveau_remboursement']" >
                <h3 class="text-orange">{{$t('Niveau ex-usine')}}</h3>           
                <table class="comparateur2 border-top-gray">
                    <tr>
                        <td class="bold"><strong> {{$t('Prix (€)')}} </strong></td>
                        <td> {{ data.prix_inami['niveau_prix'] ? format_french_number(data.prix_inami['niveau_prix']) : '-'}}</td>
                    </tr>
                    <tr>
                        <td class="bold"><strong> {{$t('Base de remboursement (€)')}} </strong></td>
                        <td> {{ data.prix_inami['niveau_remboursement'] ? format_french_number(data.prix_inami['niveau_remboursement']) : '-'}}</td>
                    </tr>
                </table>
            </div> 
        </div> 
    </div>
</template>

<script>
import { format_french_number } from '../../../utils';  

export default {
    name : 'PrixInami',
    computed : {
        data() {
            return this.$store.getters['detail/data']
        },
    },
    methods : {
        format_french_number
    }
}
</script>

<style>

</style>